import React from 'react';

export default function About() {
    return (
        <section className="section mgt-large" id="about">
            <div className="container">
                <h3 className="title is-3 is-spaced">Orpyca</h3>
                <h5 className="subtitle is-5 pb-4"> Basado en el SGD-Orfeo </h5>
                <div className="section-heading content">
                    <p className='has-text-justified'>
                        Una herramienta informática que apoya la Gestión Documental de las entidades que decidan implantarlo.
                        Disponible como una propuesta desde el Estado Colombiano para las entidades públicas
                        en Colombia. Sin embargo, gracias al esfuerzo de un grupo de
                        personas que han creído en el desarrollo tecnológico basado en la
                        colaboración y en compartir el conocimiento, hoy en día es posible que
                        cualquier persona interesada en Orpyca, pueda vincularse a una comunidad
                        comprometida con el mejoramiento de su funcionalidad y de promocionar las
                        ventajas que ofrece.
                    </p>
                    <br/>
                    <p className='has-text-justified'>
                        Orpyca esta basado en Orfeo. Nace en el 2002 como solución a unas necesidades muy importantes
                        por suplir en la Superintendencia de Servicios Públicos Domiciliarios:
                        el orden y el control en la Gestión de sus Documentos. A partir de
                        entonces la Superintendencia, gracias al apoyo de sus directivos, conformó
                        equipos de trabajo que fundando su accionar en la filosofía del Software Libre,
                        han venido mejorando día tras día las funcionalidades de este gran sistema,
                        promocionando su uso y colaborando a otras entidades y personas que se
                        han preocupado por entender como es que 'todos' podemos ser mejores
                        cada día compartiendo el único bien que al darlo a los demás NO se
                        desgasta y que por el contrario se multiplica: El conocimiento.
                        Licenciado como Software Libre.
                    </p>
                    <br/>
                    <p className='has-text-justified'>
                        Orpyca fue licenciado bajo licencia GPL (General Public License), que permite a
                        cualquier organización hacer uso de la herramienta,
                        estudiarla, modificarla y redistribuirla simplemente conociendo que ésta nació
                        en Colombia, en la Superintendencia y que cualquier modificación que se le
                        haga quedará automáticamente licenciada bajo la misma licencia GPL.
                        Funcionalidad.
                    </p>
                    <br/>
                    <p className='has-text-justified'>
                        Orpyca permite gestionar electrónicamente la producción, el trámite, el
                        almacenamiento digital y la recuperación de documentos, evitando su
                        manejo en papel, garantizando la seguridad de la información y la
                        trazabilidad de cualquier proceso que se implemente mediante su
                        funcionalidad.
                        Orpyca permite además la incorporación de los procesos propios de las
                        organizaciones optimizando su gestión y control y facilitando si se requiere la
                        certificación de la calidad de los mismos.
                    </p>
                    <ul type="1">
                        <li> Interfaz gráfica Web amigable e intuitiva.</li>
                        <li> Digitalización de documentos. Orpyca posee un módulo de digitalización
                            que genera imágenes de los documentos físicos en formato multitiff.
                        </li>
                        <li> Radicación de documentos resoluciones,memorandos etc).</li>
                        <li> Gestión sobre el documento: Reasignar, Agendar, Modificar, Archivar,
                            Incorporar en expedientes, Tipificar el documento (a partir de Tablas de
                            Retención).
                        </li>
                        <li>Ágiles búsquedas de documentos y expedientes (por fechas, usuarios,
                            tipos de documentos, etc.).
                        </li>
                        <li> Generación de expedientes virtuales siguiendo los lineamientos del
                            Archivo General de la Nación de Colombia y su parametrización para
                            otros modelos.
                        </li>
                        <li>Generación de reportes de gestión.</li>
                    </ul>
                </div>

                <h4 className="title is-4 is-spaced">Modulos del Orpyca</h4>
                <table  className="table">

                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Descripción</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>
                            <p>Radicación de entrada</p>
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Se reciben todos los documentos en la ventanilla de recepción, se
                                procesan y envían a su destino inicial (jefatura de cada
                                dependencia). Se digita la información básica del documento, al
                                cual se le asociará un archivo digital (imagen escaneada).Al
                                radicar el documento el sistema tiene la opción de impresión de
                                sticker en la impresora predeterminada del sistema, este sticker
                                será pre Configurado con los datos que la entidad desee entregar
                                a los usuarios finales
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className='has-text-justified'>
                                Módulo de digitalización
                            </p>
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Es una aplicación cliente servidor que digitaliza los documentos
                                recibidos y los asocia al registro creado desde el módulo de
                                radicación de entrada.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Interfaz de Usuario Final
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Es una herramienta de uso intuitivo similar a un Web-Mail. Permite al
                                usuario administrar diferentes carpetas, entre predefinidas y
                                personalizadas donde almacenar sus documentos de trabajo. Tiene
                                funcionalidades tales como las de ordenar o filtrar documentos,
                                para facilitar la ubicación rápida de cualquiera de ellos;
                                definir y conformar expedientes que le permitan vincular
                                documentos que están relacionados entre sí; acceder a la imagen
                                digitalizada del mismo o ingresar en un módulo de vista general
                                con el fin de conocer más información.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Gestión del Documento
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Conocer en detalle información del documento, las operaciones realizadas
                                sobre el documento desde su ingreso al sistema, modificar algunos
                                datos iniciales del registro, tipificar, clasificar y asociarlo a
                                las tablas de retención documental relacionadas con las funciones
                                y procesos. Así mismo se puede asociar aquí un documento a un
                                expediente, anexar nuevos documentos o solicitar el físico que
                                reposa en archivo. Además es posible programar alarmas que se
                                reflejan visualmente en una carpeta denominada
                                Agendados.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Radicación de Salida
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                La radicación de salida es un módulo que puede ser utilizado para
                                dar respuesta a un documento de entrada o para generar una
                                comunicación nueva. Estos documentos de salida pueden ser
                                notificaciones, oficios y otros que generen. Otra funcionalidad de
                                este módulo es la generación de documentos de salida en forma
                                masiva, usando documentos elaborados o plantillas
                                y un archivo asociado CSV, generando los respectivos radicados
                                desde cualquier dependencia y haciendo uso de la radicación
                                unificada. Adicional a la radicación de salida el sistema puede
                                ser parametrizado para realizar cualquier tipo de radicación que
                                permita identificar un grupo general de documentos como los
                                memorandos, resoluciones, circulares, etc, los cuales deban ser
                                numerados para el respectivo control.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Radicaciones de Documentos Adicionales
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Permite la parametrización de otros tipos documentales controlando las
                                secuencias como lo estimen conveniente, como lo son memorandos,
                                Circulares, etc.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Traslado de Documentos
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Traslado de documentos de un usuario a otro con niveles de control
                                definidos por las áreas. Cuenta además con una sección de envío
                                de correspondencia y de devolución de correo, para retomar
                                documentos que no pudieron ser entregados por alguna
                                razón.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Archivo y Préstamo de Documentos
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Permite procesar las solicitudes de documentos físicos, para entregarlos
                                a los funcionarios solicitantes, controlando de esta forma dónde
                                está cada documento y el tiempo que dura en poder de los
                                funcionarios. Brinda también la posibilidad de clasificar los
                                documentos físicos dependiendo de los datos ingresados por el
                                usuario desde la sección de expedientes en el módulo general y
                                de registrar los datos de ubicación física del documento durante
                                la conformación del expediente físico. Envíos Orpyca posee este
                                modulo que controla en envío de mensajería de los documentos así
                                como controlar su respectivas devoluciones, anulaciones y
                                digitalizaciones.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Consultas
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Permite a los usuarios consultar cualquier documento, a través de
                                diferentes mecanismos de filtrado según el nivel de
                                acceso.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Tablas de Retención Documental
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Permite realizar el cargue, ajuste y administración de las Tablas de
                                Retención Documental, previamente aprobadas por el AGN, brindando
                                una aplicación dinámica con el administrador y los usuarios del
                                sistema.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Seguimiento a Trámites vía Web
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Permite la consulta de trámites vía Web, con una disponibilidad de 24
                                horas. Nota: Se deberá realizar las configuraciones de red
                                necesarias para que esta funcionalidad pueda ser accedida a través
                                de Internet por los usuarios externos (ciudadanos).
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Estadísticas y Reportes
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Obtener reportes para establecer el nivel de rendimiento en los diferentes
                                procesos como número de documentos radicados, digitalizados,
                                trámite en dependencia, radicados actuales sin tramitar, etc. los
                                cuales permiten detectar posibles retrasos durante su trámite y
                                establece niveles de control y posibles contingencias de ser
                                necesario.
                            </p>

                            <p className='has-text-justified'>
                                *Además
                                de un reporte dinámico el cual le permitirá generar cualquier
                                tipo de información almacenada en el sistema. (*Nueva
                                Funcionalidad)
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Expedientes Virtuales
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Permite a los usuarios observar en línea las imágenes de los documentos
                                que reposan en el archivo físico. Este desarrollo facilita el
                                seguimiento a las diferentes series documentales, entre ellas las
                                series contratos, investigaciones, silencios administrativos,
                                entre otras.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Modulo de Manejo de Plantillas
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Permite aprovechar la ventaja de documentos de formato abierto (que
                                cuentan con la aprobación ISO ) y trabajar en múltiples
                                herramientas ofimáticas como OpenOffice, Koffice, AviWord,
                                etc.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Tablas de Retención Documental
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                Permite realizar el cargue, ajuste y administración de las Tablas de
                                Retención Documental, previamente aprobadas por el AGN, brindando
                                una aplicación dinámica con el administrador y los usuarios del
                                sistema.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Seguimiento y Trazabilidad del sistema.
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                El sistema dejara rastro de todas las operaciones que se realizan en
                                los radicados, y dejara poner notas y observaciones en las
                                transacciones que se realizan a los documentos.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Modulo de administración
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                El sistema posee un modulo para administrar usuarios, permisos,
                                áreas, TRD's, tarifas para modulo de envíos, y otros módulos de
                                sistema.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Radicación de Solicitudes y PQRS vía web
                        </td>
                        <td>
                            <p className='has-text-justified'>
                                El sistema cuenta con un módulo Web el cual le permitirá a
                                cualquier usuario radicar sus solicitudes (*Nueva Funcionalidad)
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </section>
    )
}
