import React from 'react';

export default function Foot() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="column is-4">
                    <div className="section-heading py-5">
                        <p>
                            <strong>Un servicio de transpadvisor.com</strong>
                        </p>
                    </div>
                    <p>
                        <i className="fas fa-phone"></i>  318 77 26 377
                    </p>
                    <p>
                        <i className="far fa-envelope"></i> ventas@orfeo-pyme-calidad.com
                    </p>
                    <p>
                        <i className="fas fa-map-marker-alt"></i> Calle 64 # 70 - 68
                        <br/> Bogota D.C. Colombia.
                    </p>
                </div>
            </div>
        </footer>
    )
}
