import React from 'react';
import Services from './Services';
import About from './About';
import Nav from './Nav';
import Modules from './Modules';
import Contact from './Contact';
import Banner from './Banner';
import Foot from './Foot';
import './App.css';

export default function App() {
    return (
        <div>

            <Nav />

            <Banner/>

            <Services/>

            <About/>

            <Modules/>

            <Contact/>

            <Foot/>

        </div>
    );
}
