import React from 'react';
import orpyca from "./image/orpyca.png";

export default function Banner() {
    return (
        <section className="hero is-bold is-fullheight is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container">
                    <div className='has-text-centered'>
                        <img  src={orpyca} alt='sgd-orpyca' />
                        <br/>
                        <br/>
                        <h6 className="title is-6">
                            Sistema de Gestión Documental
                        </h6>
                        <h5 className="subtitle is-6">
                            Integrado con los procesos de calidad.
                        </h5>
                    </div>
                </div>
            </div>
        </section>

    )
}
