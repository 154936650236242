import React from 'react';

export default function Contact() {
    return (
        <section className="section" id="contactos">
            <div className="container">
                <div className="column is-4">
                    <h4 className="bd-footer-title">
                        <strong href="http://transpadvisor.com">Orfeo-Pyme-Calidad</strong> by Transpadvisor.
                    </h4>
                </div>
            </div>
        </section>
    )
}
