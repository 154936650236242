import React from 'react';
import orpycaLogo from "./image/orpyca_Logo.png";

export default class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isToggleOn: false};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        this.setState({isToggleOn: !this.state.isToggleOn})
    }

    render() {

        var isActive = this.state.isToggleOn;

        return (
            <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="./">
                        <img src={orpycaLogo} alt="Orpyca" height="28"/>
                    </a>

                    <a href="./"
                       onClick={this.handleClick}
                       className="navbar-burger burger"
                       rel="noopener noreferrer"
                       aria-label="menu" aria-expanded="false"
                       data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navbarBasicExample" className={
                    isActive ? 'is-active navbar-menu' : 'navbar-menu'
                }>
                    <div className="navbar-start">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link" href="./">
                                Orpyca
                            </a>
                            <div className="navbar-dropdown">
                                <a className="navbar-item"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                   href="https://gitlab.com/orpyca">
                                            <span className="icon">
                                                <i className="fab fa-gitlab"></i>
                                            </span>
                                    <span>Repositorio</span>
                                </a>
                                <a className="navbar-item"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                   href="https://gitlab.com/orpyca/sgd-orfeo/-/archive/master/sgd-orfeo-master.zip">
                                            <span className="icon">
                                                <i className="fas fa-download"></i>
                                            </span>
                                    <span>Descargar</span>
                                </a>
                                <a className="navbar-item"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                   href="https://gitlab.com/orpyca/sgd-orfeo/-/wikis/home">
                                            <span className="icon">
                                                <i className="fab fa-wikipedia-w"></i>
                                            </span>
                                    <span>Documentación</span>
                                </a>

                                <a className="navbar-item"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                   href="https://gitlab.com/orpyca/sgd-orfeo/snippets">
                                            <span className="icon">
                                                <i className="fas fa-code-branch"></i>
                                            </span>
                                    <span>Codigos de Ayuda</span>
                                </a>

                                <hr className="navbar-divider"/>

                                <a className="navbar-item"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                   href="https://gitlab.com/orpyca/sgd-orfeo/-/issues">
                                            <span className="icon">
                                                <i className="fas fa-bug"></i>
                                            </span>
                                    <span>Reportar Errores</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="navbar-end">
                        <a className="navbar-item"
                           rel="noopener noreferrer"
                           target="_blank"
                           href="https://www.youtube.com/channel/UCh4jumjkcpfriTfTI83pIYQ">
                                                <span className="icon">
                                                    <i className="fab fa-youtube"></i>
                                                </span>
                            <span>Youtube Tutoriales</span>
                        </a>
                        <a className="navbar-item" href="#services">
                                                <span className="icon">
                                                    <i className="fas fa-bars"></i>
                                                </span>
                            <span>Servicios</span>
                        </a>
                        <a className="navbar-item" href="#modules">
                                                <span className="icon">
                                                    <i className="fas fa-layer-group"></i>
                                                </span>
                            <span>Modulos</span>
                        </a>
                        <a className="navbar-item" href="#about">
                                                <span className="icon">
                                                    <i className="fas fa-info"></i>
                                                </span>
                            <span>SGD-Pymes</span>
                        </a>
                        <a className="navbar-item" href="#contactos">
                                                <span className="icon">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                            <span>Contactenos</span>
                        </a>
                    </div>
                </div>
            </nav>
        );
    }
}

