import React from 'react';

export default function Services() {
    return (
        <div>
            <section className="section" id="services">
                <div className="container">
                    <h3 className="title is-3 is-spaced">Orpyca</h3>
                    <h5 className="subtitle is-5 pb-4">
                        Sistema de Gestión de documentos para la mediana y pequeña empresa
                    </h5>
                    <div className="columns">
                        <div className="column">
                            <div className="box">
                                <article className="media">
                                    <div className="media-content">
                                        <div className="content has-text-centered">
                                            <span className="icon is-large">
                                              <span className="fa-stack fa-lg">
                                                <i className="fas fa-rocket fa-3x "></i>
                                              </span>
                                            </span>
                                            <h1 className="title is-5 has-text-info">Esencial</h1>
                                            <h2 className="subtitle is-5 has-text-primary ">$84.000/Mes</h2>
                                            <p className="has-text-grey">
                                                20 Usuarios
                                                <br/>
                                                10G Espacio
                                                <br/>
                                                5/Horas Capacitaciones
                                                <br/>
                                                4/Meses acompañamiento implantación
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div className="column">
                            <div className="box">
                                <article className="media">
                                    <div className="media-content">
                                        <div className="content has-text-centered">
                                            <span className="icon is-large">
                                              <span className="fa-stack fa-lg">
                                                <i className="fas fa-space-shuttle fa-3x"></i>
                                              </span>
                                            </span>
                                            <h1 className="title is-5 has-text-info">Empresarial</h1>
                                            <h2 className="subtitle is-5 has-text-primary ">$167.000/Mes</h2>
                                            <p className="has-text-grey">
                                                50 Usuarios
                                                <br/>
                                                40G Espacio
                                                <br/>
                                                10/Horas Capacitaciones
                                                <br/>
                                                5/Meses acompañamiento implantación
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>

                    </div>

                    <div className="card">
                        <div className="card-content">
                            <div className="media">
                                <div className="media-left">
                                        <i className="fas fa-feather is-48x48"></i>
                                </div>
                                <div className="media-content">
                                    <p className="title is-4">Caracteristicas</p>
                                    <p className="subtitle is-6">@orpyca</p>
                                </div>
                            </div>

                            <div className="content">
                                <ul>
                                    <li> Soporte técnico 7 dias, 24 horas al día.</li>
                                    <li> Soporte funcional 5 dias, 8 horas al día.</li>
                                    <li> Capacitaciones vituales concertadas.</li>
                                    <li> Implementación del Sistema de Gestión Documental incluye los servicios profesionales
                                        para realizar la configuración, implernentación, puesta en marcha, soporte y
                                        mantenirniento del Sistema.</li>
                                    <li>
                                        Capacitaciones de usuario adicionales.
                                    </li>
                                    <li>
                                        Entrega base de datos.
                                    </li>
                                    <li>
                                        Entrega de documentos.
                                    </li>
                                    <li>
                                        Compra minima de 48 meses más un paquete de capacitación.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <h3 className="title is-3 is-spaced">Capacitaciones</h3>
                    <p className="subtitle is-6">
                        Capacitación sobre el manejo y orientación de las funcionalidades del sistema para
                        los usurios que requieren realizar tareas rutinarias, así como para aquellos que
                        requieren administrar la aplicación
                    </p>

                    <div className="columns">

                        <div className="column">
                            <div className="box">
                                <article className="media">
                                    <div className="media-content">
                                        <div className="content has-text-centered">
                                            <h1 className="title is-5 has-text-info">Cap-6/3</h1>
                                            <h2 className="subtitle is-5 has-text-primary ">$360.000</h2>
                                            <p className="has-text-grey">
                                                6 Usuarios
                                                <br/>
                                                3 Sesiones
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div className="column">
                            <div className="box">
                                <article className="media">
                                    <div className="media-content">
                                        <div className="content has-text-centered">
                                            <h1 className="title is-5 has-text-info">Cap-10/5</h1>
                                            <h2 className="subtitle is-5 has-text-primary ">$420.000</h2>
                                            <p className="has-text-grey">
                                                10 Usuarios
                                                <br/>
                                                5 Sesiones
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div className="column">
                            <div className="box">
                                <article className="media">
                                    <div className="media-content">
                                        <div className="content has-text-centered">
                                            <h1 className="title is-5 has-text-info">Cap-10/10</h1>
                                            <h2 className="subtitle is-5 has-text-primary ">$760.000</h2>
                                            <p className="has-text-grey">
                                                20 Usuarios
                                                <br/>
                                                10 Sesiones
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <h3 className="title is-3 is-spaced">Servidor Propio</h3>
                    <p>
                        Instalación sobre servidores de terceros en  los sistemas operativos Windows, Linux  ó en
                        Contenedores con integración a diferentes servicios.
                    </p>
                    <div className="columns">
                    <div className="column">
                        <div className="box">
                            <article className="media">
                                <div className="media-content">
                                    <div className="content has-text-centered">
                                        <p className="has-text-grey">
                                            Base de datos: Postgresql
                                            <br/>
                                            Servidor: Apache, Nginx, IIS
                                            <br/>
                                            PHP: versión 7+
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <h1 className="title">Codigo fuente</h1>
                    <p>
                        Realizamos actualizaciónes permanentes para la mejora continua de funcionalidades,
                        si requiere alguna mejora puede escribirnos directamente al siguiente correo.
                    </p>

                    <div className="column">
                        <div className="box">
                            <article className="media">
                                <div className="media-content">
                                    <div className="content has-text-centered">
                                        <h1 className="title is-5 has-text-info">Correo electronico</h1>
                                        <p className="has-text-grey">
                                            incoming+orpyca-sgd-orfeo-19433645-issue-<i className="fas fa-at"></i>incoming.gitlab.com
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    )
}
